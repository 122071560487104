import { Component, Input, OnInit } from '@angular/core';
import parseSubscriptionAsStatus from '@app/_helpers/parseSubscriptionAsStatus';
import { createRxValue, distinctUntilChangedJson } from '@app/_helpers/utils';
import { map, tap, withLatestFrom } from 'rxjs/operators';
import { Logger, UserSettingsQuery } from 'timeghost-api';
const log = new Logger('SubscriptionStatusRowComponent');
@Component({
  selector: 'tg-subscription-status-row',
  templateUrl: './subscription-status-row.component.html',
  styleUrls: ['./subscription-status-row.component.scss'],
})
export class SubscriptionStatusRowComponent implements OnInit {
  @Input()
  alwaysShowStatus: boolean;
  constructor(private userSettingsQuery: UserSettingsQuery) {}
  @Input()
  centerText: boolean = true;
  @Input()
  alertClasses: string = '';
  private navigateOnClick$ = createRxValue<boolean>(false);

  @Input()
  set navigateOnClick(val: boolean) {
    this.navigateOnClick$.value = val;
  }
  ngOnInit(): void {}

  readonly workspace$state = this.userSettingsQuery
    .select()
    .pipe(
      map((x) => parseSubscriptionAsStatus(x.workspace, x)),
      distinctUntilChangedJson()
    )
    .pipe(
      withLatestFrom(this.navigateOnClick$.asObservable()),
      map(([state, navOnClick]) => {
        if (!state || state.isTeamsMobile) return null;
        if (navOnClick && !state.isTeams) {
          const defaultPath = '/settings/workspace';
          const link = {
            to: ((t) => (t ? [defaultPath, ...t] : null))(
              state.isTrial
                ? state.isExpired
                  ? ['plans']
                  : null
                : state.isPaused
                ? ['subscriptions']
                : state.isExpired && state.isAborted
                ? ['subscriptions']
                : state.isExpired
                ? ['subscriptions']
                : !state.isCurrentUserInSlotRange
                ? ['subscriptions']
                : null
            ),
          };
          return {
            ...state,
            link: link?.to ? link : null,
          };
        }
        return state;
      })
    )
    .pipe(
      tap((x) => {
        log.debug(x);
      })
    );
}

<ng-container *ngIf="workspace$state | async as state">
  <ng-container
    *ngTemplateOutlet="state.link?.to ? linkAlertWrapper : defaultAlertWrapper; context: { $implicit: state.link }"
  ></ng-container>
  <ng-template #alert>
    <ng-container
      *ngTemplateOutlet="
        state.isTrial
          ? state.isExpired
            ? trialExpired
            : null
          : state.isPaused
          ? pausedAlert
          : state.isExpired && state.isAborted
          ? abortedAlert
          : state.isExpired
          ? expiredAlert
          : !state.isCurrentUserInSlotRange
          ? state.isUserSlotsExhausted
            ? exhaustedUserAlert
            : deactivatedUserAlert
          : null
      "
    ></ng-container>
  </ng-template>
  <ng-template #defaultAlertWrapper>
    <ng-container *ngTemplateOutlet="alert"></ng-container>
  </ng-template>
  <ng-template #linkAlertWrapper let-link>
    <a [routerLink]="link.to" class="mat-link cursor-pointer">
      <ng-container *ngTemplateOutlet="alert"></ng-container>
    </a>
  </ng-template>
  <ng-template #trialExpired>
    <div class="alert alert-danger flex min-h-10 items-center !py-1.5 {{ alertClasses }}">
      <div class="container leading-none" [class.text-center]="centerText">
        {{ 'subscription.trial_expired' | translate }}
      </div>
    </div>
  </ng-template>
  <ng-template #expiredAlert>
    <div class="alert alert-danger flex min-h-10 items-center !py-1.5 {{ alertClasses }}">
      <div class="container leading-none" [class.text-center]="centerText">
        {{ 'subscription.cancelled' | translate }}
      </div>
    </div>
  </ng-template>
  <ng-template #pausedAlert>
    <div class="alert alert-danger flex min-h-10 items-center !py-1.5 {{ alertClasses }}">
      <div class="container leading-none" [class.text-center]="centerText">{{ 'subscription.paused' | translate }}</div>
    </div>
  </ng-template>
  <ng-template #abortedAlert>
    <div class="alert alert-danger flex min-h-10 items-center !py-1.5 {{ alertClasses }}">
      <div class="container leading-none" [class.text-center]="centerText">
        {{ 'subscription.expired' | translate }}
      </div>
    </div>
  </ng-template>
  <ng-template #deactivatedUserAlert>
    <div class="alert alert-danger flex min-h-10 items-center !py-1.5 {{ alertClasses }}">
      <div class="container leading-none" [class.text-center]="centerText">
        {{ 'subscription.licenseNotAssigned' | translate }}
      </div>
    </div>
  </ng-template>
  <ng-template #exhaustedUserAlert>
    <div class="alert alert-danger flex min-h-10 items-center !py-1.5 {{ alertClasses }}">
      <div class="container leading-none" [class.text-center]="centerText">
        {{ 'subscription.deactivatedUser' | translate }}
      </div>
    </div>
  </ng-template>
</ng-container>
